import { componentScope, h } from "harmaja"
import * as B from "lonna"
import EmailValidator from "email-validator"
import * as H from "harmaja"
import {
  AuthResponse,
  EmailCodeValidationRequest,
  EmailCodeValidationResponse,
  EmailLoginRequest,
} from "../../../common/auth"
import { TextInput } from "../components/components"
import { LoginStore } from "./login-store"
import { AppEvent } from "../../../common/domain"

export const LoginView = ({ store }: { store: LoginStore }) => {
  return (
    <div className="popup" id="login">
      {B.view(
        store.loginStatus,
        s => s.status,
        status => {
          if (status === "initial") {
            return <InitialLogin store={store} />
          }
          if (status === "trying-token") {
            return <div>Logging in...</div>
          }
          return <span>what now?</span>
        }
      )}
    </div>
  )
}

const InitialLogin = ({ store }: { store: LoginStore }) => {
  const email = B.atom("")
  const sixDigitCode = B.atom("")
  const emailValid = B.view(email, EmailValidator.validate)
  const codeValid = B.view(sixDigitCode, code => !!code.match(/\d{6}/))
  const emailRequest = B.bus<void>()
  const emailRequested = B.view(emailRequest, () => true).pipe(B.toProperty(false, componentScope()))
  emailRequest.forEach(() => store.dispatch({ action: "email-login", email: email.get() } as EmailLoginRequest))
  const emailSent = store.events.pipe(
    B.filter((e: AuthResponse) => e.action === "email-login-response"),
    B.map(() => true),
    B.toProperty(false, componentScope())
  )
  const codeValidationRequest = B.bus<void>()
  const codeValidationResponse = store.events.pipe(
    B.filter((e: AuthResponse) => e.action === "email-code-response", componentScope())
  ) as B.EventStream<EmailCodeValidationResponse>
  const codeValidationFailed = codeValidationResponse.pipe(
    B.filter((r: EmailCodeValidationResponse) => !r.success, componentScope())
  )
  const validating = B.merge(
    B.view(codeValidationRequest, () => true),
    B.view(codeValidationFailed, () => false)
  ).pipe(B.toProperty(false, componentScope()))
  const disableSendCode = B.or(validating, B.not(codeValid))

  const codeValidationStatus = codeValidationResponse.pipe(B.toProperty(null, componentScope()))

  codeValidationRequest.forEach(() =>
    store.dispatch({
      action: "email-code-validation",
      email: email.get(),
      code: sixDigitCode.get(),
    } as EmailCodeValidationRequest)
  )
  return (
    <div className="popup-content">
      <h1>Welcome, stranger</h1>

      {B.view(emailSent, s =>
        s
          ? [
              <p>Please type the 6-digit code from the email</p>,
              <small>Check spam folder as well</small>,
              <TextInput maxLength="6" placeholder="000000" value={sixDigitCode} />,
              <button disabled={disableSendCode} onClick={() => codeValidationRequest.push()}>
                Start!
              </button>,
              <div>
                {B.view(
                  B.view(codeValidationStatus, s => s?.success),
                  s => {
                    if (s === true) return "Success! Just a moment..."
                    if (s === false) return "Not quite right, try again?"
                    return null
                  }
                )}
              </div>,
            ]
          : [
              <TextInput type="email" disabled={emailSent} placeholder="Enter your email" value={email} />,
              <button
                style={B.view(emailValid, v => (v ? {} : { display: "none" }))}
                disabled={B.or(emailRequested, B.not(emailValid))}
                onClick={() => emailRequest.push()}
              >
                Go
              </button>,
            ]
      )}
    </div>
  )
}
