import * as L from "lonna"
import Cookie from "cookie"
import jwt from "jsonwebtoken"
import { AuthInfo, AuthRequest, AuthResponse } from "../../../common/auth"
import { globalScope } from "lonna"

type LoginStatus = Initial | LoggedInCached | LoggedIn | TryingToken | Reconnecting
type Initial = { status: "initial" }
type LoggedIn = { status: "logged-in"; userInfo: AuthInfo }
type LoggedInCached = { status: "logged-in-cached"; userInfo: AuthInfo }
type TryingToken = { status: "trying-token"; sessionToken: string }
type Reconnecting = { status: "reconnecting"; userInfo: AuthInfo }

export type LoginStore = {
  loginStatus: L.Property<LoginStatus>
  loggedIn: L.Property<boolean> // logged in, confirmed with server
  loggedInCached: L.Property<boolean> // has previous login token, not confirmed with server yet (server doesn't think we're logged in)
  userInfo: L.Property<AuthInfo | null>
  dispatch: (r: AuthRequest) => void
  events: L.EventStream<AuthResponse>
}

export function loginStore(socket: typeof io.Socket): LoginStore {
  const events = L.bus<AuthResponse>()
  socket.on("message", function (kind: string, event: AuthResponse) {
    if (kind === "auth-response") {
      events.push(event)
    }
  })
  function dispatch(request: AuthRequest) {
    socket.send("auth-request", request)
  }

  if (!localStorage.sessionToken) {
    const fromCookie = Cookie.parse(document.cookie).sessiontoken
    if (fromCookie) {
      console.log("From cookie", fromCookie)
      localStorage.sessionToken = fromCookie
    }
  }

  const initialStatus: LoginStatus = localStorage.sessionToken
    ? ({ status: "logged-in-cached", userInfo: jwt.decode(localStorage.sessionToken) } as LoginStatus)
    : { status: "initial" }

  const loginStatus = events.pipe(
    L.scan(
      initialStatus,
      (status: LoginStatus, event): LoginStatus => {
        if (event.action === "challenge") {
          sendLoginToken()
          if (
            status.status === "logged-in-cached" ||
            status.status === "logged-in" ||
            status.status === "trying-token" ||
            status.status === "reconnecting"
          ) {
            return status
          }
          return { status: "initial" } as LoginStatus
        } else if (event.action === "email-code-response" && event.success) {
          localStorage.sessionToken = event.sessionToken
          return { status: "logged-in", userInfo: jwt.decode(localStorage.sessionToken) } as LoginStatus
        } else if (event.action === "token-login-response") {
          if (event.success) {
            return { status: "logged-in", userInfo: jwt.decode(localStorage.sessionToken) } as LoginStatus
          } else {
            return { status: "initial" } as LoginStatus
          }
        }
        return status
      },
      globalScope
    )
  )

  const userInfo = L.view(loginStatus, s => {
    if (s.status === "logged-in" || s.status === "logged-in-cached" || s.status === "reconnecting") {
      return s.userInfo
    }
    return null
  })

  const loggedInCached = L.view(userInfo, u => (u ? true : false))
  const loggedIn = L.view(loginStatus, s => s.status === "logged-in")

  function sendLoginToken() {
    if (localStorage.sessionToken) {
      dispatch({ action: "token-login", sessionToken: localStorage.sessionToken })
    }
  }

  loginStatus.log("LOGIN STATUS")

  return {
    loggedIn,
    loggedInCached,
    userInfo,
    loginStatus,
    dispatch,
    events,
  }
}
