import * as uuid from "uuid"

export type Id = string
export type ISOTimeStamp = string
export type GeoLoc = {
  accuracy: number
  latitude: number
  longitude: number
}
export type Completion = {
  time: ISOTimeStamp
  location?: GeoLoc
}
export type ShoppingItem = {
  id: Id
  name: string
  created: ISOTimeStamp
  completed?: Completion
  category: string | null
  location: string | null
}

export const DEFAULT_DEPARTMENT = "Muut"

export function createItem(name: string): ShoppingItem {
  const id = uuid.v4()
  return {
    id: uuid.v4(),
    name,
    created: getCurrentTime(),
    category: null,
    location: null,
  }
}

export type ShoppingListInfo = {
  id: Id
  name: string
}

export type ShoppingListSettings = {
  categorize: boolean
}

export type ShoppingList = ShoppingListInfo &
  ShoppingListSettings & {
    items: ShoppingItem[]
  }

export type AppEvent = ListEvent | ItemEvent | ShoppingEvent | ShareEvent | SuggestionEvent
export type ListEvent = AddList | RenameList | InitLists | InitList | RemoveList | ListSettings
export type ShareEvent = ShareRequest | ShareResult | ShareLinkRequest | ShareLinkResult | ShareAccept
export type AddList = { action: "list.add"; listId: Id; name: string }
export type RenameList = { action: "list.rename"; listId: Id; name: string }
export type InitLists = { action: "lists.init"; lists: ShoppingList[] }
export type InitList = { action: "list.init"; list: ShoppingList }
export type ListSettings = {
  action: "list.settings"
  listId: Id
  settings: ShoppingListSettings
}
export type RemoveList = { action: "list.remove"; listId: Id }
export type ShareRequest = {
  action: "list.share"
  listId: Id
  email: string
  message: string
}
export type ShareResult = {
  action: "list.shared"
  listId: Id
  email: string
  url: string
}
export type ShareLinkRequest = { action: "list.sharelink"; listId: Id }
export type ShareLinkResult = {
  action: "list.sharelinked"
  listId: Id
  url: string
}
export type ShareAccept = {
  action: "list.shareaccept"
  token: string
  email: string
}
export type ItemEvent = AddItem | CompleteItem | UncompleteItem | RenameItem | DeleteItem | CategorizeItem
export type AddItem = { action: "item.add"; listId: Id; item: ShoppingItem }
export type DeleteItem = { action: "item.delete"; listId: Id; itemId: Id }
export type CompleteItem = {
  action: "item.complete"
  listId: Id
  itemId: Id
  completion: Completion
}
export type UncompleteItem = {
  action: "item.uncomplete"
  listId: Id
  itemId: Id
}
export type RenameItem = {
  action: "item.rename"
  listId: Id
  itemId: Id
  name: string
}
export type CategorizeItem = {
  action: "item.categorize"
  listId: Id
  itemId: Id
  category: string | null
  location: string | null
}
export type ShoppingEvent = StartShopping | EndShopping
export type StartShopping = { action: "shopping.start"; listId: Id }
export type EndShopping = { action: "shopping.end"; listId: Id }
export type SuggestionEvent = UpdateSuggestions | RejectSuggestion
export type UpdateSuggestions = {
  action: "suggestions.update"
  suggestions: Suggestions
}
export type RejectSuggestion = {
  action: "suggestion.reject"
  listId: Id
  suggestion: SuggestionFromHistory
}

export function isItemEvent(e: AppEvent): e is ItemEvent {
  return e.action.startsWith("item.")
}
export const exampleList: ShoppingList = {
  id: "83uqofejvöij",
  name: "Groceries",
  items: [
    {
      id: "089ujq3owiajf",
      name: "milk",
      created: getCurrentTime(),
      category: null,
      location: null,
    },
  ],
  categorize: false,
}

export function newItem(name: string): ShoppingItem {
  return {
    id: uuid.v4(),
    name,
    created: getCurrentTime(),
    category: null,
    location: null,
  }
}

export function getCurrentTime(): ISOTimeStamp {
  return new Date().toISOString()
}

export type SuggestionFromHistory = {
  key: string
  name: string
  dueIn?: number // Number of days for this item to be due (= should be purchased)
  rejectedUntil: number | null // Number of days this item is still rejected or null if not rejected
  interval: number
  weight: number
}
export type SuggestionData = {
  suggestionsFromHistory: SuggestionFromHistory[]
}
export type Suggestions = Record<Id, SuggestionData>

export type SuggestionAction = {
  listId: Id
  suggestionKey: string
  timestamp: ISOTimeStamp
  action: "rejected"
}
