import { h, Fragment } from "harmaja"
import * as B from "lonna"
import * as H from "harmaja"
import { createItem, Id, ShoppingItem, SuggestionFromHistory } from "../../../common/domain"
import _ from "lodash"

export const SuggestionsView = ({
  suggestions,
  addItem,
  rejectSuggestion,
}: {
  suggestions: B.Property<SuggestionFromHistory[]>
  addItem: (item: ShoppingItem) => void
  rejectSuggestion: (suggestion: SuggestionFromHistory) => void
}) => {
  const suggestionsToShow = B.view(suggestions, s => {
    const due = s.filter(i => i.weight > 0.5)
    const top = _.orderBy(due, [i => -i.weight, i => i.key]).slice(0, 5)
    return _.orderBy(top, i => i.key)
  })
  return (
    <div className={B.view(suggestionsToShow, s => (s.length > 0 ? "suggestions" : "suggestions empty"))}>
      <span>Suggested:</span>
      <H.ListView
        observable={suggestionsToShow}
        renderItem={(s: SuggestionFromHistory) => (
          <a>
            <span onClick={() => addItem(createItem(s.name))}>{s.name}</span>
            <a className="icon delete" onClick={() => rejectSuggestion(s)} />
          </a>
        )}
      />
    </div>
  )
}
