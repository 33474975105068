import * as z from "zod";

export const Ingredient = z.object({
  name: z.string(),
  amount: z.string(),
  isPantryItem: z.boolean(),
});
export type Ingredient = z.infer<typeof Ingredient>;

export const Recipe = z.object({
  ingredients: z.array(Ingredient),
  recipeHTML: z.string(),
})
export type Recipe = z.infer<typeof Recipe>;
