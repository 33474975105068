import * as L from "lonna"
import * as H from "harmaja"
import { componentScope, h, ListView } from "harmaja"
import * as uuid from "uuid"
import { ShoppingItem, createItem, SuggestionData, SuggestionFromHistory, Id } from "../../../common/domain"
import { TextInput } from "../components/components"
import * as _ from "lodash"

type AutocompleteItem = { name: string }

export const NewItemPlaceHolder = ({
  addItem,
  editing,
  suggestionsFromHistory,
  showTip,
  rejectSuggestion
}: {
  suggestionsFromHistory: L.Property<SuggestionFromHistory[]>
  addItem: (item: ShoppingItem) => void
  editing: L.Atom<Id | null>
  showTip: L.Property<boolean>
  rejectSuggestion: (suggestion: SuggestionFromHistory) => void
}) => {
  const id = uuid.v4()
  const name = L.atom("")
  const enabled = L.view(name, n => !!n)
  const editingThis = L.atom(
    L.view(editing, e => e === id),
    setEditing => editing.set(setEditing ? id : null)
  )
  const inputElement = L.atom<HTMLElement | null>(null)
  const addIt = () => {
    editingThis.set(false)
    addItem(createItem(name.get()))
    name.set("")
    inputElement.get()?.focus()
  }
  const addByAutocomplete = (item: AutocompleteItem) => {
    name.set(item.name)
    addIt()
  }

  const autocompleteItems: L.Property<SuggestionFromHistory[]> = L.combine(suggestionsFromHistory, name, (s, n) => {
    const search = n.toLowerCase()
    const matching = s.filter(i => {
      if (i.rejectedUntil) return false
      return (search.length > 0 && i.key.startsWith(search)) || (search.length >= 3 && i.key.includes(search))
    })
    const top = _.orderBy(matching, [i => -i.weight, i => i.key]).slice(0, 10)
    return _.orderBy(top, i => i.key)
  })

  const dismissAutocomplete = L.bus<void>()

  const shouldShowAutocomplete = L.merge(
    autocompleteItems.pipe(
      L.changes,
      L.map((i: SuggestionFromHistory[]) => i.length > 0)
    ),
    L.view(dismissAutocomplete, () => false),
    L.view(L.changes(editingThis), () => false),
  ).pipe(L.toProperty(false, componentScope()))

  return (
    <div className={L.view(editingThis, e => (e ? "placeholder editing" : "placeholder"))}>
      <span className="input-area">
        <TextInput
          placeholder={L.combine(showTip, editingThis, (s, e) => (s && !e ? "Type here to add item" : ""))}
          value={name}
          onKeyUp={(e: JSX.KeyboardEvent) =>
            (e.keyCode === 13 && addIt()) || (e.keyCode == 27 && dismissAutocomplete.push())
          }
          onFocus={() => editingThis.set(true)}
          ref={inputElement.set}
        />
        {L.view(shouldShowAutocomplete, s =>
          s ? (
            <div className="autocomplete">
              <ListView
                observable={autocompleteItems}
                renderItem={item => (
                  <span className="item" onClick={() => addByAutocomplete(item)}>
                    <a className="icon" onClick={(e) => { rejectSuggestion(item); e.stopPropagation()}}>×</a>
                    {item.name}
                  </span>
                )}
              />
            </div>
          ) : null
        )}
      </span>
      {L.view(enabled, e => (e ? <button onClick={addIt}>Add</button> : null))}
    </div>
  )
}
