import * as H from "harmaja"
import { h } from "harmaja"
import * as L from "lonna"
import { TextInput } from "./components/components"
import { ShareAccept } from "../../common/domain"
import { Dispatch, ShoppingStore } from "./shopping/shopping-store"
import { AuthInfo } from "../../common/auth"
import { LoginStore } from "./login/login-store"

export const ShareUI = ({
  token,
  dispatch,
  login,
  store,
}: {
  token: string
  dispatch: Dispatch
  login: LoginStore
  store: ShoppingStore
}) => {
  let form: L.Atom<HTMLFormElement | null> = L.atom(null)
  const email = L.atom("")
  const sent = L.atom(false)
  const enabled = L.combine(form, email, sent, (form, email, sent) => !sent && !!(form && form?.matches(":valid")))
  const join = (e?: JSX.MouseEvent) => {
    sent.set(true)
    e?.preventDefault()
    dispatch({ action: "list.shareaccept", token, email: email.get() } as ShareAccept)
  }
  const loginStatus = L.view(login.loginStatus, "status")

  loginStatus.forEach(s => {
    if (s === "logged-in") {
      console.log("Logged in, joining...")
      email.set(login.userInfo.get()!.email)
      join()
    }
  })
  store.events.forEach(e => {
    if (e.action === "list.init") {
      // We got added to the list.
      document.location = "/" as any
    }
  })

  return (
    <div id="root share-ui">
      <div className="popup">
        {L.view(sent, loginStatus, (sent, s) => {
          if (s === "initial") {
            if (sent) {
              return (
                <div>
                  <h2>Email sent</h2>
                  <p>Check also spam folder</p>
                </div>
              )
            } else {
              return (
                <form ref={el => form.set(el)} className="popup-content">
                  <h2>You've been invited.</h2>
                  <TextInput required="true" placeholder="Enter your email" type="email" value={email} />
                  <button onClick={join} disabled={L.not(enabled)}>
                    Join
                  </button>
                </form>
              )
            }
          } else if (s === "logged-in") {
            return <div>Wait a sec</div>
          } else {
            return <div>Wait a sec</div>
          }
        })}
      </div>
    </div>
  )
}
