import * as H from "harmaja"
import { Fragment, h } from "harmaja"
import * as L from "lonna"
import { Checkbox, EditableSpan, If, TextInput } from "../components/components"
import { createItem, Id, ShoppingItem } from "../../../common/domain"
import { Recipe } from "../../../common/recipe"

interface IngredientItem {
  id: number
  name: string
  amount: string
  isPantryItem: boolean
  checked: boolean
}

type Status = "init" | "loading" | "loaded" | "error"


export function FindRecipeView({listId, listName, addItems, cancel}: {listId: Id, listName: L.Property<string>, addItems: (items: ShoppingItem[]) => void, cancel: () => void}) {
  const { recipeName } = parseListIdAndRecipeName()  
  const query = L.atom(recipeName ?? "")
  const status = L.atom<Status>("init")
  const disabled = L.view(query,status, (q, s) => s === "loading" || q.length < 3)
  const recipeHTML = L.atom<string | null>(null)
  const ingredients = L.atom<IngredientItem[]>([])
  const checkedIngredients = L.view(ingredients, (i) => i.filter(i => i.checked))
  const canAddToList = L.view(checkedIngredients, (checked) => checked.length > 0)
  if (recipeName) {
    search()
  }

  function addSelectedToList() {    
    addItems(checkedIngredients.get().map(i => createItem(i.name)))
  }

  async function search() {
    console.log("Search for recipe", query.get())
    status.set("loading")
    recipeHTML.set(null)
    ingredients.set([])
    const recipe = await fetchRecipe(listId, query.get())
    
    if (!recipe) {
      status.set("error")
    } else {
      ingredients.set(recipe.ingredients.map((i, index) => ({
        ...i, 
        id: index,
        checked: !i.isPantryItem
      })))
      status.set("loaded")  
      recipeHTML.set(recipe.recipeHTML)
    }
  }
  return <div className="recipe-view">
    <h2>{listName} &gt; Recipes
      <a className="backLink" onClick={cancel}>Back</a>
    </h2>
    <div className="header">
      <TextInput value={query} onKeyPress={(e: KeyboardEvent) => {
        e.key === "Enter" && search()
      }}/>
      <button disabled={disabled} onClick={search}>Find</button>
    </div>
    <div className="ingredients">
      <H.ListView
        atom={ingredients} 
        renderAtom={(key, ingredient) => { 
          return <div>
            <Checkbox checked={L.view(ingredient, "checked")}/>
            <TextInput value={L.view(ingredient, "name")}  />
            <span className="amount">{L.view(ingredient, "amount")}</span>
          </div>
        }}
        getKey={(i) => i.id}  
      />
    </div>
    <If condition={canAddToList} component={
      () => <button onClick={addSelectedToList} className="addToList">Add selected to shopping list</button>
    }/>
    <If condition={L.view(status, s => s === "error")} component={
      () => <div>Failed to load recipe, sorry!</div>
    }/>
    <If condition={L.view(status, s => s === "loading")} component={
      () => <div>Wait a second...</div>
    }/>
    <If condition={L.view(recipeHTML, h => !!h)} component={() => <RecipeHTMLView listId={listId} name={query ?? ""} html={recipeHTML}/>}/>    
  </div>
}

function RecipeHTMLView({listId, name, html}: {listId: Id, name: L.Atom<string>, html: L.Property<string | null>}) {
  let node: HTMLDivElement | null = null
  const setNode = (n: HTMLDivElement | null) => node = n
  html.onChange(html => {
    if (node) node.innerHTML = html || ""
  })
  const href= L.view(name, name => `#/recipe/${encodeURIComponent(listId)}/${name}`)
  return <div className="recipe-wrapper">
    <div className="recipe-html" ref={setNode}></div>
    <div>
      <a href={href} target="_blank">Open on new tab</a>
    </div>
  </div>
}

async function fetchRecipe(listId: Id, query: string): Promise<Recipe | null> {
  const response = await fetch(`/recipes/${encodeURIComponent(listId)}/${encodeURIComponent(query)}`)
  const json = await response.json()
  try {
    return Recipe.parse(json)
  } catch (e) {
    console.error("Failed to decode recipe", e)
    return null
  }
}

export function parseListIdAndRecipeName() {
  const match = /#\/recipe\/([a-z0-9-]+)\/(.*)/.exec(document.location.hash)
  if (match) {
    const [_, listId, recipeName] = match
    if (listId && recipeName) return { listId: decodeURIComponent(listId), recipeName: decodeURIComponent(recipeName) }
  }
  return { listId: null, recipeName: null }
}