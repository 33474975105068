import { h, HarmajaOutput } from "harmaja"
import * as B from "lonna"
import * as H from "harmaja"
import { globalScope } from "lonna"

export type Alert = {
  title: string
  message: string
}

const alertBus: B.Bus<Alert> = B.bus<Alert>()

const currentAlert: B.Property<H.HarmajaOutput | null> = alertBus.pipe(
  B.flatMapLatest((s: Alert) => B.later(3000, null).pipe(B.toProperty(<AlertBox {...s} />))),
  B.toProperty(null, globalScope)
)

const dialogBus = B.bus<H.HarmajaOutput>()
const currentDialog: B.Property<H.HarmajaOutput | null> = dialogBus.pipe(B.toProperty(null, globalScope))
const currentModal = B.combine(currentAlert, currentDialog, (a, d) => a || d)

export function showDialog(fn: (dismiss: () => any) => H.HarmajaOutput) {
  const dismiss = () => dialogBus.push([])
  dialogBus.push(fn(dismiss))
}

export const showAlert = alertBus.push

export const AlertView = () => {
  return <div className="alert-area">{currentModal}</div>
}

export const Dialog = ({ children }: { children?: any }) => {
  return (
    <div className="popup">
      <div className="popup-content">{children}</div>
    </div>
  )
}

const AlertBox = ({ title, message }: { title: string; message: string }) => {
  return <Dialog>{[<h2>{title}</h2>, <span>{message}</span>]}</Dialog>
}
