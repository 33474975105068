import { h, Fragment } from "harmaja"
import * as L from "lonna"
import * as H from "harmaja"
import * as _ from "lodash"
import {
  Id,
  ShoppingList,
  ShoppingListSettings,
  SuggestionData,
  ShoppingItem,
  ISOTimeStamp,
  SuggestionFromHistory,
  DEFAULT_DEPARTMENT,
} from "../../../common/domain"
import { ListShare } from "./ListShare"
import { NewItemPlaceHolder } from "./NewItemPlaceholder"
import { Dispatch, ShoppingStore } from "./shopping-store"
import { ShoppingItemView } from "./ShoppingItemView"
import { SuggestionsView } from "./SuggestionsView"
import { editAtom } from "./editAtom"
import { EditableSpan, If, Checkbox, IfElse } from "../components/components"
import { globalScope } from "lonna"
import { FindRecipeView } from "./FindRecipeView"

function dateAdd(a: ISOTimeStamp | Date, millis: number) {
  return new Date(new Date(a).getTime() + millis).toISOString()
}

const currentTime = L.fromPoll(1000, () => {}, globalScope).pipe(L.toStatelessProperty(() => new Date()))

export const ShoppingListView = ({
  listId,
  list,
  editing,
  showRecipe,
  suggestions,
  store,
}: {
  listId: Id
  list: L.Property<ShoppingList>
  editing: L.Atom<Id | null>
  showRecipe: L.Atom<boolean>
  suggestions: L.Property<SuggestionData>
  store: ShoppingStore
}) => {
  const suggestionsFromHistory = L.combine(list, suggestions, (l, s) =>
    s.suggestionsFromHistory.filter(s => !l.items.some(i => i.name.toLowerCase() === s.name.toLowerCase()))
  )
  const isShoppingThisList = L.view(store.shopping, s => s.state === "shopping" && s.listId === listId)
  const itemsToShow = L.combine(store.shopping, list, currentTime, (s, list, now) => {
    return list.items.filter(i => !i.completed || i.completed.time > dateAdd(now, -7000))
  })
  const editingThis = L.atom(
    L.view(editing, editing => editing === listId),
    edit => editing.set(edit ? listId : null)
  )
  const hasShoppableItems = L.view(itemsToShow, items => items.some(i => !i.completed))
  const shouldShowToggleShopping = L.and(L.or(isShoppingThisList, hasShoppableItems), L.not(editingThis))
  const shouldShowShare = L.and(editingThis, hasShoppableItems)
  const rejectSuggestion = (suggestion: SuggestionFromHistory) =>
    store.dispatch({ action: "suggestion.reject", suggestion, listId })

  const remove = () => store.dispatch({ action: "list.remove", listId })

  const [nameAtom] = editAtom(L.view(list, l => l.name))
  nameAtom.onChange(() => {
    store.dispatch({ action: "list.rename", listId, name: nameAtom.get() })
  })
  const done = () => {
    editingThis.set(false)
  }
  const addItem = (item: ShoppingItem) => store.dispatch({ action: "item.add", listId, item })
  const showSettings = L.atom(false)
  const settings = L.atom<ShoppingListSettings>(
    L.view(list, ({ categorize }) => ({ categorize })),
    settings => {
      store.dispatch({ action: "list.settings", listId, settings })
    }
  )

  return (
    <IfElse condition={showRecipe} 
    
    ifTrue={ () => <FindRecipeView 
      listId={listId}
      listName={L.view(list, l => l.name)} 
      addItems={items => {
        items.forEach(item => addItem(item))
        showRecipe.set(false)
      }}
      cancel={() => showRecipe.set(false)}
      />  } 
    
    ifFalse={() => <div className={L.view(isShoppingThisList, s => (s ? "shopping-list shopping" : "shopping-list"))}>
    <h2>
      <span>
        <EditableSpan className="name" {...{ editingThis, value: nameAtom, commit: done }} />
      </span>

      <If
        condition={editingThis}
        component={() => (
          <>
            <span className="icon settings" onClick={() => showSettings.modify(s => !s)} />
            <span className="controls">
              <button className="save" onClick={done}>
                Done
              </button>
              <button className="delete" onClick={remove}>
                Delete
              </button>
              <If condition={shouldShowShare} component={() => <ListShare list={list} store={store} />} />
            </span>
          </>
        )}
      />

      {L.combine(shouldShowToggleShopping, isShoppingThisList, (show, shopping) =>
        show ? (
          shopping ? (
            <a className="shopping" onClick={() => store.dispatch({ action: "shopping.end", listId })}>
              <ShoppingCartIcon />
              Done shopping
            </a>
          ) : (
            <><a className="shopping" onClick={() => store.dispatch({ action: "shopping.start", listId })}>
              <ShoppingCartIcon />
              Shop!
            </a>
            <If 
              condition={L.view(list, "categorize")}
              component={() => <>
                &nbsp;
                <a className="shopping" onClick={() => showRecipe.set(true)} >
                <RecipeIcon/>
                  Recipe                
                </a>              
              </>}
            />
            </>
          )
        ) : null
      )}
    </h2>
    <ShoppingListSettingsView {...{ showSettings, settings }} />
    {L.view(
      settings,
      s => s.categorize,
      c =>
        c ? (
          <CategorizedListView {...{ itemsToShow, listId, isShoppingThisList, editing, dispatch: store.dispatch }} />
        ) : (
          <ItemsListView {...{ itemsToShow, listId, isShoppingThisList, editing, dispatch: store.dispatch }} />
        )
    )}

    <NewItemPlaceHolder
      suggestionsFromHistory={suggestionsFromHistory}
      addItem={addItem}
      editing={editing}
      showTip={L.view(itemsToShow, items => items.length === 0)}
      rejectSuggestion={rejectSuggestion}
    />
    <SuggestionsView suggestions={suggestionsFromHistory} addItem={addItem} rejectSuggestion={rejectSuggestion} />
  </div>} />
    
  )
}

const ShoppingListSettingsView = ({
  showSettings,
  settings,
}: {
  showSettings: L.Atom<boolean>
  settings: L.Atom<ShoppingListSettings>
}) => {
  return L.view(showSettings, s =>
    s ? (
      <div className="list-settings">
        <div className="row">
          <Checkbox {...{ checked: L.view(settings, "categorize") }}>Group by department</Checkbox>
        </div>
      </div>
    ) : null
  )
}

const CategorizedListView = ({
  itemsToShow,
  listId,
  isShoppingThisList,
  editing,
  dispatch,
}: {
  itemsToShow: L.Property<ShoppingItem[]>
  listId: Id
  isShoppingThisList: L.Property<boolean>
  editing: L.Atom<Id | null>
  dispatch: Dispatch
}) => {
  const categoryMinSize = 2
  const categories = L.view(itemsToShow, (items: ShoppingItem[]) => {
    const cats = Object.entries(_.groupBy(items, i => i.category || DEFAULT_DEPARTMENT)).map(([category, items]) => ({
      category,
      items,
    }))
    const validCats = cats.filter(c => c.category !== DEFAULT_DEPARTMENT && c.items.length >= categoryMinSize)

    // Collapse all "too small" categories into DEFAULT_DEPARTMENT
    const defaultCat = {
      category: DEFAULT_DEPARTMENT,
      items: cats.filter(c => !validCats.includes(c)).flatMap(c => c.items),
    }

    return [
      ..._.sortBy(validCats, category => category.category.toLowerCase()),
      ...(defaultCat.items.length > 0 ? [defaultCat] : []),
    ]
  })

  return L.view(
    categories,
    cs => cs.length > 1,
    l =>
      l ? (
        <H.ListView
          observable={categories}
          renderObservable={(key, category) => {
            const itemsHere = L.view(category, c => c.items)
            return (
              <div className="category">
                <div className="department">{key}</div>
                <ItemsListView {...{ itemsToShow: itemsHere, listId, isShoppingThisList, editing, dispatch }} />
              </div>
            )
          }}
          getKey={c => c.category}
        />
      ) : (
        <ItemsListView {...{ itemsToShow, listId, isShoppingThisList, editing, dispatch }} />
      )
  )
}

const ItemsListView = ({
  itemsToShow,
  listId,
  isShoppingThisList,
  editing,
  dispatch,
}: {
  itemsToShow: L.Property<ShoppingItem[]>
  listId: Id
  isShoppingThisList: L.Property<boolean>
  editing: L.Atom<Id | null>
  dispatch: Dispatch
}) => {
  const sortedItems = L.view(itemsToShow, items => _.sortBy(items, x => x.name.toLowerCase()))
  return (
    <H.ListView
      observable={sortedItems}
      renderObservable={(itemId: Id, item) => (
        <ShoppingItemView
          listId={listId}
          itemId={itemId}
          item={item}
          shopping={isShoppingThisList}
          editing={editing}
          dispatch={dispatch}
        />
      )}
      getKey={item => item.id}
    />
  )
}

const ShoppingCartIcon = () => <span className="icon shopping" />
const RecipeIcon = () => <span className="icon add" />
