import { h } from "harmaja"
import * as H from "harmaja"
import * as B from "lonna"
import * as uuid from "uuid"
import { Id } from "../../../common/domain"
import { TextInput } from "../components/components"
import { ShoppingStore } from "./shopping-store"

export const AddListButton = ({ store }: { store: ShoppingStore }) => {
  const shouldShowPopup = B.atom(false)

  const addList = (listId: Id, name: string) => {
    store.dispatch({ action: "list.add", listId, name })
    shouldShowPopup.set(false)
  }

  const cancel = () => shouldShowPopup.set(false)

  return (
    <button
      onClick={e => {
        if (e.target === e.currentTarget) shouldShowPopup.set(true)
      }}
    >
      <span className="icon add" />
      Add List
      {B.view(shouldShowPopup, show => (show ? <AddListPopup addList={addList} cancel={cancel} /> : null))}
    </button>
  )
}

const AddListPopup = ({ addList, cancel }: { addList: (id: Id, name: string) => void; cancel: () => void }) => {
  const name = B.atom("")
  const enabled = B.view(name, n => !!n)

  const addIt = () => {
    addList(uuid.v4(), name.get())
  }

  return (
    <div
      className="popup"
      onClick={e => {
        if (e.target === e.currentTarget) cancel()
      }}
      onKeyDown={e => {
        if (e.keyCode === 27) cancel()
      }}
    >
      <div className="popup-content">
        <h2>Add new list</h2>
        <TextInput ref={(el: HTMLElement) => el.focus()} placeholder="type to add new list" value={name} />
        <button disabled={B.not(enabled)} onClick={addIt}>
          Add list
        </button>
      </div>
    </div>
  )
}
