import * as H from "harmaja"
import { h } from "harmaja"
import { ShoppingView } from "./shopping/ShoppingView"
import { AlertView } from "./alert"
import { LoginView } from "./login/LoginView"
import io from "socket.io-client"
import "./app.scss"
import { ShareUI } from "./share-handler"
import { shoppingStore } from "./shopping/shopping-store"
import { loginStore } from "./login/login-store"
import { syncStatusStore } from "./sync-status/sync-status-store"
import { Header } from "./components/Header"
import * as B from "lonna"

const App = () => {
  const socket = io()
  const store = shoppingStore(socket)
  const login = loginStore(socket)
  const syncStatus = syncStatusStore(socket, store.queueSize)

  const shareToken = document.location.pathname.match(/\/share-ui\/(.*)/)?.[1]
  if (shareToken) {
    return <ShareUI token={shareToken} dispatch={store.dispatch} login={login} store={store} />
  }
  return (
    <div id="root">
      {B.view(login.loggedInCached, i =>
        i ? (
          [<Header login={login} syncStatus={syncStatus} />, <ShoppingView store={store} />, <AlertView />]
        ) : (
          <LoginView store={login} />
        )
      )}
    </div>
  )
}

H.mount(<App />, document.getElementById("root")!)
