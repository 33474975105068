import { h } from "harmaja"
import * as H from "harmaja"
import * as L from "lonna"
import { LoginStore } from "../login/login-store"
import { SyncStatus } from "../sync-status/sync-status-store"

export const Header = ({ login, syncStatus }: { login: LoginStore; syncStatus: L.Property<SyncStatus> }) => {
  const logout = () => {
    localStorage.clear()
    document.location.reload()
  }
  function showStatus(status: SyncStatus): string {
    switch (status) {
      case "offline":
        return "Offline"
      case "up-to-date":
        return "Up to date"
      case "sync-pending":
        return "Unsaved changes"
    }
  }
  return (
    <header>
      <h1>Ostax</h1>
      {L.view(login.userInfo, u =>
        u ? (
          <span className="logged-in-info">
            <span>{`Logged in as ${u.email}`}</span>
            <a onClick={logout} className="logout">
              logout
            </a>
            <span className={L.view(syncStatus, s => "sync-status " + s)}>
              <span title={L.view(syncStatus, showStatus)} className="symbol">
                ⬤
              </span>
            </span>
          </span>
        ) : null
      )}
    </header>
  )
}
