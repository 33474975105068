import { componentScope, h } from "harmaja"
import * as B from "lonna"
import EmailValidator from "email-validator"
import { AppEvent, ShareLinkRequest, ShareRequest, ShareResult, ShoppingList } from "../../../common/domain"
import { Dialog, showAlert, showDialog } from "../alert"
import { TextArea, TextInput } from "../components/components"
import { ShoppingStore } from "./shopping-store"

export const ListShare = ({ list, store }: { list: B.Property<ShoppingList>; store: ShoppingStore }) => {
  const showPopup = B.bus<void>()
  showPopup.pipe(B.map(list)).forEach(list => {
    const sendShare = (dismiss: () => void) => (r: ShareRequest | ShareLinkRequest | null) => {
      if (r) {
        const shareUrl = store.events.pipe(
          B.filter((e: AppEvent) => e.action === "list.shared" || e.action == "list.sharelinked"),
          B.map(e => (e as ShareResult).url)
        )

        const shareResult = B.merge(shareUrl, B.later(10000, null)).pipe(B.take(1))

        store.dispatch(r)

        shareResult.forEach((url: string | null) => {
          if (url) {
            if (r.action === "list.share") {
              showAlert({
                title: "Invitation sent!",
                message: "Tell your friend to check spam folder too.",
              })
            } else {
              navigator.clipboard
                .writeText(url)
                .then(() => {
                  showAlert({
                    title: "Link copied.",
                    message: "Go and share it!",
                  })
                })
                .catch(() => {
                  showDialog(dismiss => {
                    return (
                      <Dialog>
                        <h2>Link created.</h2>
                        <a
                          onClick={() => {
                            navigator.clipboard.writeText(url)
                            dismiss()
                          }}
                        >
                          Copy to clipboard
                        </a>
                      </Dialog>
                    )
                  })
                })
            }
          }
          dismiss()
        })
      } else {
        dismiss()
      }
    }

    showDialog(dismiss => <ListSharePopup list={list} sendShare={sendShare(dismiss)} />)
  })
  return (
    <span className="share">
      <button className="share" onClick={() => showPopup.push()}>
        Share
      </button>
    </span>
  )
}

const ListSharePopup = ({
  list,
  sendShare,
}: {
  list: ShoppingList
  sendShare: (r: ShareRequest | ShareLinkRequest | null) => void
}) => {
  const email = B.atom("")
  const message = B.atom(`Please join the shopping list ${list.name}`)
  const sent = B.atom(false)
  const enabledEmail = B.combine(email, sent, (email, sent) => !sent && EmailValidator.validate(email))
  const enabledLink = B.combine(email, sent, (email, sent) => !sent && !email)

  const cancel = () => {
    sendShare(null)
  }

  const share = (e: JSX.MouseEvent) => {
    e.preventDefault()
    sent.set(true)
    sendShare({ action: "list.share", listId: list.id, email: email.get(), message: message.get() })
  }

  const shareLink = (e: any) => {
    e.preventDefault()
    sent.set(true)
    sendShare({ action: "list.sharelink", listId: list.id })
  }

  return (
    <div
      className="popup"
      onClick={e => {
        if (e.target === e.currentTarget) cancel()
      }}
      onKeyDown={e => {
        if (e.keyCode === 27) cancel()
      }}
    >
      {B.view(sent, s =>
        s ? (
          <div className="popup-content">
            <p>{B.view(email, e => (e ? "Sending invitation..." : "Please wait..."))}</p>
          </div>
        ) : (
          <div className="popup-content">
            <h3>Share list {list.name}</h3>
            <TextInput
              required="true"
              type="email"
              placeholder="Enter email to send share link"
              value={email}
              ref={(el: HTMLElement) => el.focus()}
            />
            <TextArea
              style={B.view(enabledEmail, e => (e ? {} : { display: "none" }))}
              placeholder="Add custom message"
              value={message}
            />
            <button onClick={share} disabled={B.not(enabledEmail)}>
              Send link
            </button>
            {B.view(enabledLink, e =>
              e
                ? [
                    <p>Or just</p>,
                    <button onClick={shareLink} disabled={B.not(enabledLink)}>
                      Get share link
                    </button>,
                  ]
                : null
            )}
          </div>
        )
      )}
    </div>
  )
}
