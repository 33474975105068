import * as L from "lonna"
import { globalScope } from "lonna"
import {
  AppEvent,
  getCurrentTime,
  Id,
  ISOTimeStamp,
  ShoppingItem,
  ShoppingList,
  Suggestions,
} from "../../../common/domain"
import MessageQueue from "./message-queue"
import * as _ from "lodash"

export type ShoppingAppState = {
  lists: ShoppingList[]
  suggestions: Suggestions
  shopping: ShoppingState
}

export type ShoppingState = Shopping | Adding
export type Shopping = { state: "shopping"; listId: Id; started: ISOTimeStamp }
export type Adding = { state: "adding" }

export type ShoppingStore = ReturnType<typeof shoppingStore>

export type Dispatch = (e: AppEvent) => void

export function shoppingStore(socket: typeof io.Socket) {
  const uiEvents = L.bus<AppEvent>()
  const serverEvents = L.bus<AppEvent>()
  const messageQueue = MessageQueue(socket)
  socket.on("connect", () => {
    console.log("Socket connected")
  })
  socket.on("message", function (kind: string, event: AppEvent) {
    if (kind === "app-event") {
      serverEvents.push(event)
    }
  })
  uiEvents.forEach(messageQueue.enqueue)

  uiEvents.log("UI")
  serverEvents.log("Server")

  const events = L.merge(uiEvents, serverEvents)

  const eventsReducer = (lists: ShoppingList[], event: AppEvent) => {
    switch (event.action) {
      case "list.add":
        return lists.concat([{ id: event.listId, name: event.name, items: [], categorize: true }])
      case "lists.init":
        let currentLists = event.lists
        messageQueue.replayBuffer(replayEvent => (currentLists = eventsReducer(currentLists, replayEvent)))
        messageQueue.flush()
        return _.orderBy(currentLists, l => _.orderBy(l.items, ["created"], ["desc"])[0]?.created ?? "0", "desc")
      case "list.init":
        return lists.concat([event.list])
      case "list.remove":
        return lists.filter(l => l.id !== event.listId)
      case "list.rename":
        return modifyList(lists, event.listId, l => ({ ...l, name: event.name }))
      case "list.settings":
        return modifyList(lists, event.listId, l => ({ ...l, ...event.settings }))
      case "item.add":
        return addToList(lists, event.listId, event.item)
      case "item.complete":
        return modifyItem(lists, event.listId, event.itemId, i => ({ ...i, completed: event.completion }))
      case "item.uncomplete":
        return modifyItem(lists, event.listId, event.itemId, i => ({ ...i, completed: undefined }))
      case "item.rename":
        return modifyItem(lists, event.listId, event.itemId, i => ({ ...i, name: event.name }))
      case "item.categorize":
        return modifyItem(lists, event.listId, event.itemId, i => ({
          ...i,
          category: event.category,
          location: event.location,
        }))
      case "item.delete":
        return removeFromList(lists, event.listId, event.itemId)
      default:
        console.warn("Unhandled event", event)
    }
    return lists
  }

  const lists = events.pipe(L.scan(LocalShoppingStorage.initialLists as ShoppingList[], eventsReducer, globalScope))
  lists.pipe(L.changes, L.debounce(500)).forEach(LocalShoppingStorage.saveLists)

  const suggestions = events.pipe(
    L.scan(
      LocalShoppingStorage.initialSuggestions,
      (suggestions, event) => {
        if (event.action === "suggestions.update") return event.suggestions
        if (event.action === "suggestion.reject") {
          return Object.fromEntries(
            Object.entries(suggestions).map(([listId, suggestions]) => [
              listId,
              {
                ...suggestions,
                suggestionsFromHistory: suggestions.suggestionsFromHistory.filter(s => {
                  console.log("HERE", s)
                  return event.listId !== listId || s.key !== event.suggestion.key
                }),
              },
            ])
          )
        }
        return suggestions
      },
      globalScope
    )
  )
  suggestions.pipe(L.changes, L.debounce(500)).forEach(LocalShoppingStorage.saveSuggestions)

  const shopping = events.pipe(
    L.scan(
      { state: "adding" } as ShoppingState,
      (state: ShoppingState, event: AppEvent): ShoppingState => {
        switch (event.action) {
          case "shopping.start":
            return { state: "shopping", listId: event.listId, started: getCurrentTime() }
          case "shopping.end":
            return { state: "adding" }
        }
        return state
      },
      globalScope
    )
  )

  function modifyList(lists: ShoppingList[], listId: string, f: (l: ShoppingList) => ShoppingList) {
    return lists.map(list => (list.id === listId ? f(list) : list))
  }
  function addToList(lists: ShoppingList[], listId: string, item: ShoppingItem) {
    return modifyList(lists, listId, list => ({ ...list, items: list.items.concat(item) }))
  }
  function removeFromList(lists: ShoppingList[], listId: string, itemId: Id) {
    return modifyList(lists, listId, list => ({ ...list, items: list.items.filter(item => item.id !== itemId) }))
  }
  function modifyItem(lists: ShoppingList[], listId: Id, itemId: Id, f: (i: ShoppingItem) => ShoppingItem) {
    return modifyList(lists, listId, list => ({
      ...list,
      items: list.items.map(item => (item.id === itemId ? f(item) : item)),
    }))
  }

  const state = L.combineTemplateS(
    {
      lists,
      shopping,
      suggestions,
    },
    globalScope
  )

  return {
    state,
    dispatch: uiEvents.push,
    events,
    shopping: L.view(state, "shopping"),
    lists: L.view(state, "lists"),
    suggestions: L.view(state, "suggestions"),
    queueSize: messageQueue.queueSize,
  }
}

const LocalShoppingStorage = (() => {
  const initialLists: ShoppingList[] = localStorage.lists ? JSON.parse(localStorage.lists) : [] // TODO: the localStorage approach is not very scalable here.
  const initialSuggestions: Suggestions = localStorage.suggestions ? JSON.parse(localStorage.suggestions) : {}

  return {
    initialLists,
    initialSuggestions,
    saveLists: (lists: ShoppingList[]) => (localStorage.lists = JSON.stringify(lists)),
    saveSuggestions: (suggestions: Suggestions) => (localStorage.suggestions = JSON.stringify(suggestions)),
  }
})()
