import { AppEvent, isItemEvent } from "../../../common/domain"
import io from "socket.io-client"
import * as B from "lonna"
import * as H from "harmaja"

const resendWaitTime = 10000

function readFromLocalStorage(): AppEvent[] {
  if (localStorage.messageQueue) {
    try {
      const items = JSON.parse(localStorage.messageQueue) as AppEvent[]
      return items.filter(isItemEvent)
    } catch (e) {
      console.warn("Failed to parse stored messageQueue", e)
    }
  }
  return []
}

export default function (socket: typeof io.Socket) {
  const queue = B.atom<AppEvent[]>(readFromLocalStorage())
  let waitingForAck = 0

  function sendHead() {
    const q = queue.get()
    const now = new Date().getTime()
    if (q.length && now - waitingForAck > resendWaitTime) {
      waitingForAck = now
      socket.send("app-event", q[0], ack)
    }
  }

  function ack() {
    waitingForAck = 0
    queue.modify(q => q.slice(1))
    sendHead()
  }

  function enqueue(event: AppEvent) {
    queue.modify(q => q.concat(event))
    sendHead()
  }

  function replayBuffer(fn: (e: AppEvent) => void) {
    queue.get().forEach(fn)
  }

  queue.pipe(B.throttle(2000)).forEach(q => (localStorage.messageQueue = JSON.stringify(q)))
  const queueSize = B.view(queue, q => q.length)

  queueSize.log("Queue size")

  return {
    enqueue,
    replayBuffer,
    flush: sendHead,
    queueSize: queueSize,
  }
}
