import { h } from "harmaja"
import * as B from "lonna"
import * as H from "harmaja"
import { ShoppingStore } from "./shopping-store"
import { ShoppingListView } from "./ShoppingListView"
import { AddListButton } from "./AddListButton"
import { Id } from "../../../common/domain"
import { If } from "../components/components"
import { parseListIdAndRecipeName } from "./FindRecipeView"

export const ShoppingView = ({ store }: { store: ShoppingStore }) => {
  const { listId } = parseListIdAndRecipeName()
  const editing = B.atom<Id | null>(null)
  const showRecipe = B.atom<Id | null>(listId)
  return (
    <div id="shopping" className={B.view(store.shopping, s => (s.state === "shopping" ? "shopping" : undefined))}>
      <div className="lists">
        <H.ListView
          observable={store.lists}
          renderObservable={(listId: string, list) => (
            <If 
              condition={B.view(showRecipe, r => !r || r === listId)}
              component={() => <ShoppingListView
                suggestions={B.view(store.suggestions, entries => entries[listId] || { suggestionsFromHistory: [] })}
                listId={listId}
                list={list}
                store={store}
                editing={editing}
                showRecipe={B.atom(
                  B.view(showRecipe, r => r === listId),
                  show => showRecipe.set(show ? listId : null)
                )}
              />}
            />            
          )}
          getKey={list => list.id}
        />
      </div>
      <If  
        condition={B.view(showRecipe, e => e === null)}
        component={() => <BottomBar store={store} />}
      />      
    </div>
  )
}

const BottomBar = ({ store }: { store: ShoppingStore }) => {
  return (
    <div id="bottom-bar">
      <AddListButton store={store} />
    </div>
  )
}
