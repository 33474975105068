import { h, Fragment } from "harmaja"
import * as B from "lonna"
import * as H from "harmaja"
import { getCurrentTime, Id, ShoppingItem, GeoLoc } from "../../../common/domain"
import { Checkbox, EditableSpan } from "../components/components"
import { Dispatch } from "./shopping-store"
import { editAtom } from "./editAtom"

export const ShoppingItemView = ({
  listId,
  itemId,
  item,
  shopping,
  editing,
  dispatch,
}: {
  itemId: Id
  listId: Id
  item: B.Property<ShoppingItem>
  shopping: B.Property<boolean>
  editing: B.Atom<Id | null>
  dispatch: Dispatch
}) => {
  const checked = B.atom(
    B.view(item, i => !!i.completed),
    checked => (checked ? complete() : uncomplete())
  )

  const complete = () => {
    const completion = { time: getCurrentTime() }
    dispatch({ action: "item.complete", listId, itemId, completion })
    getCurrentPosition().then(
      location =>
        location && dispatch({ action: "item.complete", listId, itemId, completion: { ...completion, location } })
    )
  }
  const uncomplete = () => dispatch({ action: "item.uncomplete", listId, itemId })

  return (
    <div className={B.view(checked, c => (c ? "item checked" : "item"))}>
      {B.view(shopping, s =>
        s ? (
          <ShoppingItemShoppingView {...{ listId, itemId, item, dispatch, checked }} />
        ) : (
          <ShoppingItemAddingView {...{ listId, itemId, item, editing, dispatch, checked }} />
        )
      )}
    </div>
  )
}

export const ShoppingItemAddingView = ({
  listId,
  itemId,
  item,
  editing,
  dispatch,
  checked,
}: {
  itemId: Id
  listId: Id
  item: B.Property<ShoppingItem>
  editing: B.Atom<Id | null>
  dispatch: Dispatch
  checked: B.Atom<boolean>
}) => {
  const editingThis = B.atom(
    B.view(editing, editing => editing === itemId),
    edit => editing.set(edit ? itemId : null)
  )
  const globalName = B.view(item, "name")
  const [nameAtom] = editAtom(globalName)
  const done = () => {
    editingThis.set(false)
  }
  editingThis.forEach(editing => {
    if (!editing && nameAtom.get() !== globalName.get()) {
      dispatch({ action: "item.rename", listId, itemId, name: nameAtom.get() })
    }
  })
  const remove = () => {
    dispatch({ action: "item.delete", listId, itemId })
    editingThis.set(false)
  }
  return (
    <>
      <EditableSpan className="name" {...{ value: nameAtom, editingThis, commit: done }} />
      {B.view(editingThis, e =>
        e ? (
          <span className="controls">
            <button disabled={checked} onClick={done}>
              Done
            </button>
            <button disabled={checked} className="delete" onClick={remove}>
              Delete
            </button>
            <button className="complete" onClick={() => checked.modify(x => !x)}>
              {B.view(checked, c => (c ? "Cancel" : "Complete"))}
            </button>
          </span>
        ) : null
      )}
    </>
  )
}

export const ShoppingItemShoppingView = ({
  listId,
  itemId,
  item,
  dispatch,
  checked,
}: {
  itemId: Id
  listId: Id
  item: B.Property<ShoppingItem>
  dispatch: Dispatch
  checked: B.Atom<boolean>
}) => {
  const clicked = () => checked.modify(c => !c)

  return (
    <>
      <Checkbox checked={checked} />
      <span className="name" onClick={clicked}>
        {B.view(item, i => i.name)}
      </span>
      <small className="location">{B.view(item, i => i.location)}</small>
    </>
  )
}

function getCurrentPosition(): Promise<GeoLoc | undefined> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude, accuracy } }) => {
        resolve({ latitude, longitude, accuracy })
      },
      e => {
        console.error(e)
        reject(e)
      },
      {
        maximumAge: 5 * 60 * 3600,
        timeout: 30000,
        enableHighAccuracy: true,
      }
    )
  })
}
